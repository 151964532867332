import React, { useState } from 'react';
import api from '../../api';
import './ordercard.scss';

import { useTranslation } from 'react-i18next';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';

i18n.use(initReactI18next).init({
    resources: {
        uz: { translation: translateUz },
        ru: { translation: translateRu },
    },
    lng: 'uz',
    fallbackLng: 'uz',
});

const OrderCard = () => {
    const { t } = useTranslation();

    const [name, setName] = useState('');
    const [contact, setContact] = useState('');
    const [region, setRegion] = useState('');
    const [error, setError] = useState('');

    const changeLanguages = (value) => {
        i18n.changeLanguage(value);
    };

    const changeLanguageHandler = (e) => {
        changeLanguages(e.target.value);
    };

    const sendInformation = async (e) => {
        e.preventDefault();

        // Validatsiya: Ism, kontakt, va viloyatni tekshirish
        if (!name || !contact || !region) {
            setError(t('fillAllFields'));
            return;
        }

        // Kontakt validatsiyasi (telefon yoki email)
        const phoneRegex = /^[0-9]{9,12}$/; // Telefon uchun
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Email uchun

        if (!phoneRegex.test(contact) && !emailRegex.test(contact)) {
            setError(t('validPhoneOrEmail'));
            return;
        }

        // Xatolikni tozalash
        setError('');

        // Yuboriladigan ma'lumotlar
        const data = {
            names: name,
            phone: contact,
            region: region,
        };

        try {
            const endPoint = '/client';
            const response = await api.post(endPoint, data);
            console.log(response.data);

            alert(t('successMessage')); // Muvaffaqiyatli yuborilganligi haqida xabar
        } catch (error) {
            console.error(error);
            alert(t('errorMessage')); // Xato bo'lganini ko'rsatish
        }
    };

    return (
        <div className="order_card">
            <h1 className="order_card_title">{t('advice')}</h1>
            <form onSubmit={sendInformation} className="form">
                {error && <p className="error-message">{error}</p>}
                <p
                    className="order_card_description"
                    style={{ textAlign: 'center', margin: '0 auto 30px' }}
                >
                    {t('writeOrder')}
                </p>
                <input
                    className="form-control order_input"
                    type="text"
                    placeholder={t('name')}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input
                    className="form-control order_input my-4"
                    type="text"
                    placeholder={t('phoneOrEmail')}
                    value={contact}
                    onChange={(e) => setContact(e.target.value)}
                />
                <select
                    className="form-control order_input my-4"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                >
                    <option value="">{t('selectRegion')}</option>
                    <option value="andijon">{t('andijan')}</option>
                    <option value="buxoro">{t('bukhara')}</option>
                    <option value="jizzax">{t('jizzakh')}</option>
                    <option value="qashqadaryo">{t('kashkadarya')}</option>
                    <option value="navoiy">{t('navoi')}</option>
                    <option value="namangan">{t('namangan')}</option>
                    <option value="qoraqalpogiston">{t('karakalpakstan')}</option>
                    <option value="samarqand">{t('samarkand')}</option>
                    <option value="sirdaryo">{t('sirdarya')}</option>
                    <option value="surxondaryo">{t('surkhandarya')}</option>
                    <option value="fargona">{t('fergana')}</option>
                    <option value="xorazm">{t('khorezm')}</option>
                    <option value="toshkent">{t('tashkent')}</option>
                </select>
                <button type="submit" className="order_card_btn form-control">
                    {t('sendPetition')}
                </button>
            </form>
        </div>
    );
};

export default OrderCard;
