
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { BrowserRouter, Routes, Router, Route } from 'react-router-dom'
import Home from './pages/home/home';
import Ocompany from './pages/ocompany/ocompani';
import Otziv from './pages/otziv/otzive';
import Kontakt from './pages/contact/kontakt';
import Authlayout from './layout/authlayout';
import Estrozed from './pages/estrozed/estrozed';
import Androzed from './pages/androzed/androzed';
import AboutPage from './pages/about';
import ProductInfoFull from './components/productinfofull/Porductinfofull';



function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Authlayout />}>
          <Route path='/' element={<Home />} />
          <Route path='/okompani' element={<Ocompany />} />
          <Route path='/otziv' element={<Otziv />} />
          <Route path='/kontakt' element={<Kontakt />} />
          <Route path='/estrozed' element={<Estrozed />} />
          <Route path='/androzed' element={<Androzed />} />
          <Route path='/productinfofull/:id' element={<ProductInfoFull />} />
        </Route>
        <Route path='/about' element={<AboutPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
