// import React from 'react';
import React, { useState } from 'react';
import './pagenetion.scss';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom'

i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})


const itemsPerPage = 3;

const ProductPage = ({ data, type }) => {

  const navigate = useNavigate();

  console.log(data);
  const { t, i18n } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const currentItems = data.slice(startIndex, startIndex + itemsPerPage);

  const productInfoFull = (id) => {
    navigate(`/productinfofull/${id}`);
  }


  return (
    <div id='checimal' className="product-page">
      <div className="products">
        {currentItems.map((item, index) => (
          <div key={index} className="product">
            {type === 'products' ? (
              <>
                <img className='product-img' src={item.image} alt={item.name} />
                <h2>{item.name}</h2>
                <ul>
                  {item.benefits && item.benefits.map((benefit, benefitIndex) => (
                    <li key={benefitIndex}>{benefit}</li>
                  ))}
                </ul>
                <button onClick={() => { productInfoFull(item.id) }}>{t("informProduct")}</button>
              </>
            ) : (
              <img src={item.img} alt={`Sertificate ${index + 1}`} />
            )}
          </div>
        ))}
      </div>
      <div className="pagination">
        <button className="previous" onClick={handlePrevious}>{t("pageinationPrev")}</button>
        <div className="pages">
          {[...Array(totalPages).keys()].map(page => (
            <button
              key={page + 1}
              className={`page ${currentPage === page + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(page + 1)}
            >
              {page + 1}
            </button>
          ))}
        </div>
        <button className="next" onClick={handleNext}>{t("pagenationNext")}</button>
      </div>
    </div>
  );
};

export default ProductPage;
