import React from 'react'
import './footer.scss'

import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';


i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const productsList = [
  {
    name: "Андрозед"
  },
  {
    name: "Эстрозед"
  },
  {
    name: "Лактозед"
  },
  {
    name: "Минивит"
  },
  {
    name: "Офтозед"
  },
  {
    name: "Энтрокал"
  },
  {
    name: "Айвис"
  },
  {
    name: "Кальций"
  },
  {
    name: "Магний"
  },
  {
    name: "Сизигол"
  }
]



const Footer = ({ changeLanguages }) => {

  const { t } = useTranslation()

  const changeLanguageHandler = (e) => {
    // console.log(e.target.value);
    changeLanguages(e.target.value)
  }


  return (
    <div className='footer'>
      <div className="container">
        <div className="footer_container  text-light">
          <div className="footer_description">
            <p className='footer_description_contact'>{t('call')}</p>
            <a className='footer_description_hpone' href="tel:+9998991234567">+998 88 304 00 03
            </a>
            <p className='footer_description_email'>{t('email')}</p>
            <a className='footer_description_email_name' href="prozed@gmail.com">prozed.llc@gmail.com</a>
            <p className='footer_description_address'>{t('address')}</p>
            <p className='footer_description_address_name'>{t('location')}</p>
          </div>
          <div className="footer_apps">
            <p className='footer_apps_name'>{t('app')}</p>
            <div className="footer_apps_store">
              <a href="https://www.instagram.com/prozeduzb?igsh=MW43MXljazNoaWR1MA=="> <i class="fa-brands fa-instagram cursor-pointer" style={{ color: '#fcfcfc' }}></i></a>
              <a href="https://youtube.com/@pro-zed.llc2023?si=a2yIkBRKqNpDg6Vx"><i class="fa-brands fa-youtube cursor-pointer" style={{ color: '#fcfcfc' }}></i></a>
              <a href="https://t.me/prozed_uz"><i class="fa-brands fa-telegram cursor-pointer" style={{ color: '#fafafa' }}></i></a>
            </div>
            <p className='footer_apps_company'>{t('companyName')}</p>
            <p className='footer_apps_company_brand'>{t("certificateNumber")}</p>
            <p className='footer_apps_company_brand'>2007872</p>
          </div>
          <div className="footer_products">
            <p className='footer_products_name'>{t('products')}</p>
            <div style={{ display: 'flex', gap: '15px' }}>
              <div className="">
                {
                  productsList.slice(0, Math.floor(productsList.length / 2)).map((product, index) => {
                    return <p key={index}>{product.name}</p>
                  })
                }
              </div>
              <div className="">
                {
                  productsList.slice(Math.floor(productsList.length / 2), -1).map((product, index) => {
                    return <p key={index}>{product.name}</p>
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
