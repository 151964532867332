import React, { useEffect, useState } from 'react';
import './prductinfofull.scss';
import { useParams } from 'react-router-dom';
import api from '../../api';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';
import { useTranslation } from 'react-i18next';

// i18n konfiguratsiyasi
i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu },
  },
  lng: 'uz',
  fallbackLng: 'uz',
});

export default function ProductInfoFull() {
  const [product, setProduct] = useState({});
  const [description, setDescription] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [feedback, setFeedback] = useState('');
  const { id } = useParams();
  const { t } = useTranslation();

  // Mahsulotni API orqali olish
  useEffect(() => {
    async function getProduct() {
      const endPoint = `/product/${id}`;
      try {
        const response = await api.get(endPoint);
        if (response.data) {
          setProduct(response.data);
        } else {
          console.warn('API javobida maʼlumot topilmadi.');
        }
      } catch (error) {
        console.error('API chaqiruvida xatolik:', error);
      }
    }
    getProduct();
  }, [id]); // Faqat ID o'zgarganda ishlaydi

  // Tavsifni til o'zgarganda yangilash
  useEffect(() => {
    if (product && (product.description_ru || product.description_uz)) {
      const newDescription =
        i18n.language === 'ru'
          ? product.description_ru || 'Описание отсутствует'
          : product.description_uz || 'Tavsif mavjud emas';
      setDescription(newDescription);
    } else {
      console.warn('Tavsif uchun mos maydon topilmadi.');
    }
  }, [product, i18n.language]); // Mahsulot yoki til o'zgarganda ishlaydi

  // Fikr yuborish funksiyasi
  const sendFeedback = async () => {
    if (!name || !email || !feedback) {
      alert('Iltimos, barcha maydonlarni toʻldiring!');
      return;
    }
    const endPoint = `/review`;
    const dataFeedback = {
      gmail: email,
      id_product: id,
      name: name,
      texts: feedback,
    };

    try {
      const response = await api.post(endPoint, dataFeedback);
      if (response.data) {
        alert('Fikr muvaffaqiyatli yuborildi!');
        setName('');
        setEmail('');
        setFeedback('');
      } else {
        console.warn('API javobida maʼlumot topilmadi.');
      }
    } catch (error) {
      console.error('API chaqiruvida xatolik:', error);
    }
  };

  return (
    <div className="product-info-full">
      {/* Rasim */}
      <img
        className="product-image"
        src={product.image || 'https://via.placeholder.com/400'}
        alt={product.name || 'Product'}
      />

      {/* Nom */}
      <h2 className="product-name">{product.name || 'Mahsulot nomi mavjud emas'}</h2>

      {/* Tavsif */}
      <div
        className="product-description"
        dangerouslySetInnerHTML={{
          __html: description || '<p>Maʼlumot mavjud emas</p>',
        }}
      ></div>

      <div className="">
        <p
          style={{
            fontWeight: '700',
            fontSize: '56px',
            lineHeight: '68.26px',
            color: '#17255A',
            margin: '100px 0 50px 0',
          }}
        >
          {t('leaveReview')}
        </p>
        <form className="ostalos-comment">
          <input
            className="otziv_input"
            type="text"
            placeholder="Ism"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="otziv_input my-4"
            type="text"
            placeholder="Email/Telefon"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </form>
        <textarea
          className="otziv_textarea"
          name=""
          id=""
          cols="30"
          rows="10"
          placeholder="Fikr yozing"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea>
      </div>
      <button onClick={sendFeedback} className="text-light ostalos-comment-btn">
        {t('sendReview')}
      </button>
    </div>
  );
}
