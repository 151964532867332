import { Home } from '@mui/icons-material'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './navbar.scss'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';


i18n.use(initReactI18next).init({
    resources: {
        uz: { translation: translateUz },
        ru: { translation: translateRu }
    },
    lng: 'uz',
    fallbackLng: 'uz',
})




const Navbar = () => {
    const [langImgUz, setLangImgUz] = useState(true)
    const [langImgRu, setLangImgRu] = useState(false)
    const { t } = useTranslation()

    const changeLanguages = (value) => {
        i18n.changeLanguage(value)

        if (value === 'uz') {
            setLangImgUz(true);
            setLangImgRu(false)
        } else if (value === 'ru') {
            setLangImgUz(false);
            setLangImgRu(true)
        }
    }

    const changeLanguageHandler = (e) => {
        const selectedLang = e.target.value;
        changeLanguages(selectedLang)
    }



    return (
        <div className="navbar">
            <div className='container'>
                <div className="navabr_description">
                    <div className="navabr_description_title ">
                        <div className="">
                            <img src="../images/pages/pro-zed.png" alt="pro-zed" />
                        </div>
                        <div className="d-flex gap-5">
                            <img style={{ width: '80px' }} src="../images/pages/halol.png" alt="halol" />
                            <img style={{ width: '80px' }} src="../images/pages/iso.png" alt="iso" />
                            <img style={{ width: '80px' }} src="../images/pages/gmp2.png" alt="iso" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between gap-5">
                        <div className="d-flex gap-4">
                            <a href="https://www.instagram.com/prozeduzb?igsh=MW43MXljazNoaWR1MA=="> <i class="fa-brands fa-instagram cursor-pointer" style={{ color: '#FE2475' }}></i></a>
                           <a href="https://youtube.com/@pro-zed.llc2023?si=a2yIkBRKqNpDg6Vx"> <i class="fa-brands fa-youtube cursor-pointer" style={{ color: 'red' }}></i></a>
                            <a href="https://t.me/prozed_uz"><i class="fa-brands fa-telegram cursor-pointer" style={{ color: '#28A8E9' }}></i></a>
                        </div>
                        <div style={{ width: '1100px' }} className="d-flex align-items-center justify-content-between">
                            <div className="d-flex gap-5">
                                <Link className='text-black' style={{ fontFamily: 'inter', fontSize: '22px', lineHeight: '26.63px', textAlign: 'right', textDecoration: 'none' }} to={'/'}>{t('about')}</Link>
                                <Link className='text-black' style={{ fontFamily: 'inter', fontSize: '22px', lineHeight: '26.63px', textAlign: 'right', textDecoration: 'none' }} to={'/okompani'}>{t('aboutUs')}</Link>
                                <Link className='text-black' style={{ fontFamily: 'inter', fontSize: '22px', lineHeight: '26.63px', textAlign: 'right', textDecoration: 'none' }} to={'/otziv'}>{t('review')}</Link>
                                <Link className='text-black' style={{ fontFamily: 'inter', fontSize: '22px', lineHeight: '26.63px', textAlign: 'right', textDecoration: 'none' }} to={'/kontakt'}>{t('contact')}</Link>
                            </div>
                            <div className="d-flex gap-2 align-items-center gap-3">
                                <a style={{ fontFamily: 'inter', fontSize: "18px", textAlign: 'right', textDecoration: 'none' }} className='m-0 text-black cursor-pointer' href="tel:+998 91 123 45 67">+ 998 88 304 00 03
                                </a>
                                <div className="d-flex gap-2">
                                    <div className="d-flex gap-2">
                                        <div className="">
                                            {langImgUz &&
                                                <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src="../images/pages/flaguz.jpg" alt="" />
                                            }
                                            {
                                                langImgRu &&
                                                <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src="../images/pages/flugru.jpg" alt="" />
                                            }

                                        </div>
                                        <div className="transparent-background">
                                            <select onChange={changeLanguageHandler} className='text-black cursor-pointer' style={{ border: 'none', backgroundColor: 'transparent', fontSize: '22px' }} name="" id="">
                                                <option value="uz">Uz</option>
                                                <option value="ru">Ru</option>
                                            </select>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* divice */}

                {/* <div className="container "> */}
                <div className="navbar_devise ">
                    <div className="navabr_devise_description d-flex align-items-center justify-content-between">
                        <div className="">
                            <img src="../images/pages/pro-zed.png" alt="logo" className='logo' />
                        </div>
                        <div className="d-flex gap-3">
                            <div class="dropdown">
                                <i data-bs-toggle="dropdown" aria-expanded="false" class="fa-solid fa-bars fa-xl "></i>
                                <div class="dropdown-menu">
                                    <Link to={'/'} class="dropdown-item">{t('about')}</Link>
                                    <Link to={'/okompani'} class="dropdown-item">{t('aboutUs')}</Link>
                                    <Link to={'/otziv'} class="dropdown-item">{t('review')}</Link>
                                    <Link to={'/kontakt'} class="dropdown-item">{t('contact')}</Link>
                                </div>
                            </div>

                            <div className="transparent-background d-flex gap-2">
                                <div className="">
                                    {langImgUz &&
                                        <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src="../images/pages/flaguz.jpg" alt="" />
                                    }
                                    {
                                        langImgRu &&
                                        <img style={{ width: '30px', height: '30px', borderRadius: '50%' }} src="../images/pages/flugru.jpg" alt="" />
                                    }

                                </div>
                                <select onChange={changeLanguageHandler} className='text-black' style={{ border: 'none', backgroundColor: 'transparent' }} name="" id="">
                                    <option value="uz">Uz</option>
                                    <option value="ru">Ru</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        // </div>

    )
}

export default Navbar
