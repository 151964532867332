
import React from 'react'
import './estrozed.scss'

import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';

i18n.use(initReactI18next).init({
    resources: {
      uz: { translation: translateUz },
      ru: { translation: translateRu }
    },
    lng: 'uz',
    fallbackLng: 'uz'
  })




const Estrozed = () => {
    const { t } = useTranslation()
    return (
        <div className="estrozed">
            <div class="container">
                <img className='androzed_img_info' src="../images/pages/ESTROZED.png" alt="Androzed" />
                <h6 style={{ marginTop: "20px" }} className='androzed_title'>{t("estrozedabout")}</h6>

                <h2 className='estrozed_information'>{t("estrozedinstrucy")}</h2>

                <h3 className='estrozed_description'>{t("estrozedtradename")}</h3>
                <h4>{t("estrozedcapsules")}</h4>
                <h5>{t("estrozedcompound")}</h5>

                <p>{t("estrozedаргинин")}</p>
                <p>{t("estrozedкарнитин")}</p>
                <p>{t("estrozedvitaminE")}</p>
                <p>{t("estrozedoxide")}</p>
                <p>{t("estrozedfolicAcid")}</p>
                <p>{t("estrozedmagnesiumCarbonate")}</p>
                <p>{t("estrozedvitaminB6")}</p>
                <p>{t("estrozedvitaminB8")}</p>
                <p>{t("estrozedvitaminC")}</p>
                <p>{t("estrozedCoenzyme")}</p>
                <p>{t("estrozedselenium")}</p>
                <p>{t("estrozediodine")}</p>
                <p>{t("estrozedchasteberry")}</p>
                <p>{t("estrozedisaflavone")}</p>

                <h5>{t("estrozedexcipients")}</h5>

                <h5>{t("estrozedProperties")}</h5>

                <p><h5>{t("estrozedl_аргинин")}</h5>{t("estrozedaminoAcid")}
                    <h5>{t("estrozedl_carnitine")}</h5>{t("estrozedforWeightLoss")}
                    <h5>{t("estrozedvitaminE2")}</h5>{t("estrozedimprovesOxygen")}
                    <h5>{t("estrozedzinc")}</h5>{t("estrozedpregnancy")}
                    <h5>{t("estrozedfolicAcid")}</h5>{t("estrozednecessaryGrowth")}
                    <h5>{t("estrozedmagnesium")}</h5>{t("estrozedbiochemicalProcesses")}
                    <h5>{t("estrozedvitaminB6")}</h5>{t("estrozedaminoAcids")}
                    <h5>{t("estrozedвитаминВ8")}</h5>{t("estrozedforWomen")}
                    <h5>{t("estrozedvitaminC")}</h5>{t("estrozedsynthesisOfCollagen")}
                    <h5>{t("estrozedcoenzyme")}</h5>{t("estrozedhealthWholeBody")}
                    <h5>{t("estrozedselenium")}</h5>{t("estrozedincreasingFertility")}
                    <h5>{t("estrozediodine2")}</h5>{t("estrozediodineThyroxine")}
                    <h5>{t("estrozedchasteberryExtract")}</h5> {t("estrozedpregnancyRelease")}
                    <h5>{t("estrozedsoyIsaflavone")}</h5>{t("estrozedincreasedSweating")}</p>
                <p>{t("estrozeddrugDoesNotContain")}</p>


                <h6>{t("estrozedindicationsForUse")}</h6>

                <li>	{t("estrozednormalizesMenopausePeriod")}</li>
                <li>	{t("estrozednormalizesThickness")}</li>
                <li>	{t("estrozedfunctionalHyperprolactinemia")}</li>
                <li>	{t("estrozedimprovesFemaleSystem")}</li>
                <li>	{t("estrozeddoublesPregnancyRate")}</li>
                <li>	{t("estrozedimprovesIVFresults")}</li>
                <li>	{t("estrozedimprovesFemaleBody")}</li>
                <li>	{t("estrozedsaturatesWithVitamins")}</li>
                <li>	{t("estrozedpreventsPrematureAging")}</li>
                <li>	{t("estrozedreplacementFemaleBody")}</li>
                <li>	{t("estrozedimprovesHairAndNails")}</li>
                <li>	{t("estrozedbeneficialCardiovascularSystem")}</li>
                <li>	{t("estrozednormalizesLipidMetabolism")}</li>
                <li>	{t("estrozedreducesBlood")}</li>
                <li>	{t("estrozedpreventsDevelopmentCancer")}</li>
                <li>	{t("estrozedincreasesLibido")}</li>
                <li>	{t("estrozedusedForMenstrualIrregularities")}</li>







                <h5>{t("estrozedDosageMethod")}</h5>

                <p>{t("estrozedtakenWithMeals")}</p>
                <p>{t("estrozeddurationTreatment")}</p>
                <p>{t("estrozedpregnancyMonth")}</p>

                <h5>{t("estrozedcontraindications")}</h5>

                <p>{t("estrozedHypersensitivityComponents")}</p>

                <h5>{t("estrozedsideEffects")}</h5>
                <p>{t("estrozedsideEffectsOccur")}</p>

                <h5>{t("estrozedstorageConditions")}</h5>
                <p>{t("estrozedstorePoolPlace")}</p>

                <h5>{t("estrozedvacationConditions")}</h5>
                <p>{t("estrozedavailableWithoutPrescription")}</p>

            </div>
        </div>
    )
};

export default Estrozed