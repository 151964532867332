import React , {useEffect, useState} from 'react'
import './otziv.scss';
import api from '../../api';

import { useTranslation } from 'react-i18next'

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translateRu from '../../locale/translationEn';
import translateUz from '../../locale/translationUz';


i18n.use(initReactI18next).init({
  resources: {
    uz: { translation: translateUz },
    ru: { translation: translateRu }
  },
  lng: 'uz',
  fallbackLng: 'uz'
})

const Otziv = () => {

  const { t } = useTranslation()

  const [otzive, setOtzive] = useState([])

  useEffect(() => {
    async function getFeedback() {
      const endPoint = `/review`;
      try {
        const response = await api.get(endPoint);
        if (response.data) {
          setOtzive(response.data.reviews);
          console.log(response.data.reviews);
          
        } else {
          console.warn('API javobida maʼlumot topilmadi.');
        }
      } catch (error) {
        console.error('API chaqiruvida xatolik:', error);
      }
    }
    getFeedback();
  }, []);


  return (
    // <Otziv/>
    <div className="otziv">
      <div className='container'>
        <p style={{ fontWeight: '700', fontSize: "56px", lineHeight: "68.26px", color: "#17255A" }} className='pt-5 pb-3'>{t('review')}</p>
        {
          otzive.map((item, index) => {
            return (
              <div className="div">
                <img src={item.product_img} alt={item.name} className='otziv-img' />
                <p>{new Date(item.created_at).toLocaleString()}</p>
                <h1>{item.name}</h1>
                <p><b></b>{item.texts}</p>
              </div>
            )
          })
        }

      </div>
    </div>
  )
}

export default Otziv
